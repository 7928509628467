import React from "react";
import { Row, Col, Card } from "react-bootstrap";

import PageTitle from "../../../layouts/PageTitle";
import Acc from "./Acc";
import Bfi from "./Bfi";
import Wom from "./Wom";
import Myb from "./Myb";
// import Batumbu from "./Batumbu";

import accImage from "../../../../images/natadana/acc.png";
import bfiImage from "../../../../images/natadana/bfi.png";
import womImage from "../../../../images/natadana/wom.jpg";
import mybImage from "../../../../images/natadana/maybank.jpg";
import bcafImage from "../../../../images/natadana/bcaf.png";
import batumbuImage from "../../../../images/natadana/batumbu.png";
import danaiImage from "../../../../images/natadana/danai.png";
import kmfImage from "../../../../images/natadana/kmf.png";
import BcafChart from "./Bcaf";
import BatumbuChart from "./Batumbu";
import DanaiChart from "./Danai";
import KmfChart from "./Kmf";
import { Carousel } from "@trendyol-js/react-carousel";

function Bulan() {
  return (
    <>
      <PageTitle motherMenu="Charts" activeMenu="Hari" />
      <Carousel
        show={1}
        slide={1}
        transition={1.5}
        autoSwipe={60000}
        swiping={true}
        a11y={false}
        swipeOn={0.5}
        rightArrow={false}
        leftArrow={false}
      >
      {/* <Row> */}
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img src={accImage} className="rounded" alt="" />
            </Card.Header>
            <Card.Body>
              <Acc />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img src={bfiImage} className="rounded" alt="" />
            </Card.Header>
            <Card.Body>
              <Bfi />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={womImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <Wom />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={mybImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <Myb />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={bcafImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <BcafChart />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={batumbuImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <BatumbuChart />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={danaiImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <DanaiChart />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={kmfImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <KmfChart />
            </Card.Body>
          </Card>
        </Col>
      {/* </Row> */}
      </Carousel>
    </>
  );
}

export default Bulan;
